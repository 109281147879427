import React, { useEffect } from "react";
import { BaseLayout } from "./layouts";
import { useAuth } from "app/providers/Auth";
import useLocales from "app/shared/hooks/useLocales";
import enUS from "antd/lib/locale/en_US";
import viVN from "antd/lib/locale/vi_VN";
import { ConfigProvider } from "antd";
import { ThemeProvider, useTheme } from "antd-theme";
import moment from "moment";
import "moment/locale/vi";

// ConfigProvider.config({
//   theme: {
//     primaryColor: 'red'
//   }
// })

const initialTheme = {
  name: "default",
  variables: { "primary-color": "#00ff00" },
};

export default function App() {
  const { isInitialized } = useAuth();
  const { currentLang, onChangeLang } = useLocales();
  const [theme, setTheme] = React.useState(initialTheme);

  moment.locale("vi");

  useEffect(() => {
    (async () => {
      await onChangeLang(currentLang.value);
    })();
  }, []);

  return (
    <ThemeProvider theme={theme} onChange={(value: any) => setTheme(value)}>
      <ConfigProvider locale={viVN}>
        {!isInitialized ? <div>Loading</div> : <BaseLayout />}
      </ConfigProvider>
    </ThemeProvider>
  );
}
